import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InviteState {
  message: string;
}

const initialState: InviteState = {
  message: '',
};

const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    resetMessage(state) {
      state.message = initialState.message;
    }
  },
});

export const { setMessage, resetMessage } = inviteSlice.actions;
export default inviteSlice.reducer;
