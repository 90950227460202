import React, { useEffect, useState } from 'react'
import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import {
  Button,
  Form,
  Heading,
  P,
  TextField,
  Section,
  EmailField,
  Icon,
  PasswordField,
  TOSLinks
} from 'components'
import { constantStrings, NavigationTarget } from 'data'
import { readError } from 'helpers'
import { useFeatureToggle, useFormField, useRedirect, useAppWideState } from 'hooks'
import { builder, } from 'routes'

interface Props {
  // groupId?: GroupId
}
const SetSelfGroup: React.FC<Props> = () => {
  const { isSignedIn, signIn, showError } = useAppWideState()
  const [isLoading, setIsLoading] = useState(false)

  const email = useFormField('')
  const password = useFormField('')
  const name = useFormField('')
  const recipientName = useFormField('')

  const redirect = useRedirect()

  const groupFor = sessionStorage.getItem('groupFor')
  const groupName = sessionStorage.getItem('groupName')
  const message = sessionStorage.getItem('groupDesc')
  const showNewShareFlow = useFeatureToggle('newShareFlow')

  const goalAmountAmount = sessionStorage.getItem('goalAmount.amount')
  const goalAmountCurrency = sessionStorage.getItem('goalAmount.currency')
  if (!goalAmountAmount || !goalAmountCurrency) {
    redirect(new NavigationTarget({ url: builder.group.setGroupGoal }))
  }
  const goalAmount: Money = {
    amount: goalAmountAmount as string,
    currency: goalAmountCurrency as Currency,
  }

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: "APP_GC_ACCOUNT_CREATE", url: window.location.href })
  }, [])

  const createForOther = async (): Promise<GroupId> => {
    const group = await postData('/api/group/create/other', {
      recipientName,
      name: groupName,
      goalAmount,
      publicMessage: message,
    })
    return group.data.id
  }

  const createForSelf = async (): Promise<GroupId> => {
    const group = await postData('/api/group/create/self', {
      name: groupName,
      goalAmount,
      publicMessage: message,
    })
    return group.data.id
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      if (!isSignedIn) {
        if ((groupFor === 'other' && recipientName.isValid) || (groupFor === 'self')) {
          const registration = await postData('/api/auth/register/password', { email, name, password })
          const token = registration.data.token
          signIn(token)
        }
      }

      if (groupFor === 'other' && recipientName.isValid) {
        const groupId = await createForOther()
        if(!showNewShareFlow){
          redirect(new NavigationTarget({
            url: builder.group.inviteContacts(groupId),
          }))
        } else {
          redirect(new NavigationTarget({
            url: builder.group.inviteByEmail(groupId),
          }))
        }
        return true
      }

      if (groupFor === 'other' && !recipientName.isValid) {
        setIsLoading(false)
        showError(readError('Error:', 'Recipient Name cannot be blank'))
      }

      if (groupFor === 'self') {
        const groupId = await createForSelf()
        if(!showNewShareFlow){
          redirect(new NavigationTarget({
            url: builder.group.inviteContacts(groupId),
          }))
        } else {
          redirect(new NavigationTarget({
            url: builder.group.inviteByEmail(groupId),
          }))
        }
        return true
      }
    } catch (error) {
      setIsLoading(false)
      showError(readError(error, 'Could not create your account, please try again'))
    }
  }

  // const valid =
  //   email.isValid &&
  //   password.isValid &&
  //   name.isValid

  //  disabled={!valid}

  return (
    <BaseLayout>
      <Section id="CreateGroupForSelf">
        <Form onSubmit={onSubmit}>
          {/*<Heading>Save Your Progress</Heading>*/}
        <Heading>Save Your Progress</Heading>
          {groupFor === 'other' &&
            <div>
              <P classes="mg-top--2xl font-semibold sib--top-mg-sm">Who will receive the money?</P>
              <TextField binding={recipientName} placeholder="Recipient's Full Name" required />
              <P classes="font-semibold">You’ll invite the Recipient to add their bank account on the following steps.</P>
              <P>A US Social Security Number or Tax Identification Number is required to receive monthly deposits.</P>
            </div>
          }
          {!isSignedIn && <>
            <P classes="mg-top--2xl font-semibold sib--top-mg-sm">Please create your account:</P>
            <TextField binding={name} placeholder="Your Full Name" required />
            <EmailField binding={email} intent="register" required />
            <div className="formWrap">
              <Icon icon="lock" size="small" />
              <PasswordField binding={password} type="create" required />
            </div>
            {/*<PhoneNumberField binding={phone} intent="register" required />*/}
            <TOSLinks />
          </>}
            <Button type="submit" text="Save & Continue" loading={isLoading} />
            <P centered={true}>{constantStrings.inviteOthersAfter}</P>
        </Form>
      </Section>
    </BaseLayout>
  )
}

export default SetSelfGroup
