import React from 'react'
import classNames from 'classnames'

import './index.css'

import checked from 'assets/svg-icons/checkbox-checked.svg'
import clipboard from 'assets/svg-icons/clipboard.svg'
import clock from 'assets/svg-icons/clock.svg'
import currency from 'assets/svg-icons/currency.svg'
import email from 'assets/svg-icons/email.svg'
import lock from 'assets/svg-icons/lock.svg';
import phone from 'assets/svg-icons/phone.svg'
import unchecked from 'assets/svg-icons/checkbox-unchecked.svg'
import user from 'assets/svg-icons/user.svg'
import arrow from 'assets/svg-icons/circle-right.svg'
import paperclip from 'assets/svg-icons/paperclip.svg'
import checkedthin from 'assets/svg-icons/checkbox-thin-checked.svg'
import uncheckedthin from 'assets/svg-icons/checkbox-thin-unchecked.svg'

type Props = {
  icon: IconName
  inline?: boolean
  size?: IconSize
  classes?: string
}
const Icon: React.FC<Props> = ({ icon, inline = false, size = 'medium', classes }) => {
  const iconSources: Record<string, string> = {
    checked,
    clipboard,
    clock,
    currency,
    email,
    lock,
    phone,
    unchecked,
    user,
    paperclip,
    checkedthin,
    uncheckedthin,
    arrow
  }
  let src = iconSources[icon]

  return <img
    alt={`${icon} icon`}
    className={classNames("Icon", size, classes, { inline })}
    src={src}
  />
}

export default Icon
