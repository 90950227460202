import { Heading, P, AnchorButton } from 'components';
import { groupIdInUrl, mustBeSignedIn, builder } from 'routes';
import { useApiQuery, useRedirect } from 'hooks';
import { NavigationTarget } from 'data'
import { showConfetti } from 'helpers'
import DetectAndRedirectUser from 'helpers/DetectAndRedirectUser';
import BaseLayout from 'blocks/layout/base-layout';
import { useEffect } from 'react';

interface SuccessProps {
  groupId: GroupId;
}

export const InviteByEmailSuccess = (props: SuccessProps) => {
  const { groupId } = props as { groupId: GroupId }
  const redirect = useRedirect()
  const group = useApiQuery('/api/group/entity', { groupId })
  const memberInviteCount = group?.memberInviteCount || 0

  useEffect(() => {
    showConfetti()
  })

  const handleDoneInviting = () => {
    redirect(new NavigationTarget({
      url: builder.group.view(groupId),
    }))
  };

  return (
    <BaseLayout>
      <div className="success-page font-16px">
        <div className="success-card">
          <Heading>Invites Sent!</Heading>
          <P classes="success-message">Nicely done.</P>
          <P classes="success-count">You sent <strong>{memberInviteCount}</strong> invites!</P>
          <P classes="success-instruction">
            Invite more contacts or continue to your group below.
          </P>

          <DetectAndRedirectUser groupId={groupId} btnText="Send More Invites?" />

          <AnchorButton extraClass="d-flex j-center mg-top--md" onClick={handleDoneInviting}>
            I’m done inviting others.
          </AnchorButton>
        </div>
      </div>
    </BaseLayout>
  );
};

export default mustBeSignedIn(groupIdInUrl(InviteByEmailSuccess));
