import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Contact {
  id: string;
  name: string;
  email: string;
  selected: boolean;
}

interface ContactsState {
  contacts: Contact[];
  selectedContacts: Contact[];
  inputText: string;
  errorMessage: string | null;
}

const initialState: ContactsState = {
  contacts: [],
  selectedContacts: [],
  inputText: '',
  errorMessage: null,
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContacts(state, action: PayloadAction<Contact[]>) {
      state.contacts = action.payload;
    },
    toggleContactSelection(state, action: PayloadAction<string>) {
      state.contacts = state.contacts.map(contact =>
        contact.id === action.payload ? { ...contact, selected: !contact.selected } : contact
      );
      state.selectedContacts = state.contacts.filter(contact => contact.selected);
    },
    setInputText(state, action: PayloadAction<string>) {
      state.inputText = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<string | null>) {
      state.errorMessage = action.payload;
    },
  },
});

export const { setContacts, toggleContactSelection, setInputText, setErrorMessage } = contactsSlice.actions;
export default contactsSlice.reducer;
