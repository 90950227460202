import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setMessage } from '../../redux/inviteSlice';

import {
  Heading,
  Loader,
  TextArea,
  P,
  Icon,
  Button,
} from 'components';
import { NavigationTarget } from 'data';
import { postData } from 'helpers/post-data';
import DetectAndRedirectUser from 'helpers/DetectAndRedirectUser';
import { useApiQuery, useRedirect, useAppWideState, useFormField } from 'hooks';

import { builder, groupIdInUrl, mustBeSignedIn } from 'routes';
import { getFirstName } from 'helpers';

import BaseLayout from 'blocks/layout/base-layout';

import './index.scss';
import { Link } from 'react-router-dom';

interface InviteByEmailProps {
  groupId: GroupId;
}

export const InviteByEmail = (props: InviteByEmailProps) => {
  const { groupId } = props;
  const dispatch = useDispatch();

  const { isSignedIn, user } = useAppWideState();
  const signature = isSignedIn && user ? '\n\n -' + getFirstName(user.name) : '';
  const inviteMessage = `Hello,\n\nI’m inviting you to help support me using AutoGive - the private, monthly way to give together.\n\nPlease visit the link to give now.\n\nThank you for your support!${signature}`;

  const message = useSelector((state: RootState) => state.invite.message);

  const initialText = useFormField(inviteMessage)

  useEffect(() => {
    if (!message) {
      dispatch(setMessage(initialText.value));
    }
  }, [dispatch, initialText, message]);

  const [textAreaValue, setTextAreaValue] = useState(message || initialText.value);
  const [isTextAreaDisabled, setIsTextAreaDisabled] = useState(true);
  const [isAnchorButtonDisabled, setIsAnchorButtonDisabled] = useState(false);

  const handleEditClick = () => {
    if (isTextAreaDisabled) {
      setIsTextAreaDisabled(false);
      setIsAnchorButtonDisabled(true);
    } else {
      dispatch(setMessage(textAreaValue));
      setIsTextAreaDisabled(true);
      setIsAnchorButtonDisabled(false);
    }
  };

  const buttonPillClasses = isTextAreaDisabled ? 'pill' : 'pill save';
  const buttonPillText = isTextAreaDisabled ? 'Edit' : 'Save';

  const group = useApiQuery('/api/group/entity', { groupId });
  const permissions = useApiQuery('/api/group/me', { groupId });
  const redirect = useRedirect();

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: 'APP_GC_INVITE_CONTACTS', url: window.location.href, groupId });
  }, [groupId]);

  if (!group || !permissions) {
    return <Loader />;
  }

  if (!permissions.permissions.inviteMembers) {
    redirect(new NavigationTarget({ url: builder.group.view(groupId) }));
    return null;
  }

  const memberLandingPage = builder.share.memberLanding(groupId);

  return (
    <BaseLayout>
      <div className="inviteByEmail">
        <Heading>Send Invites!</Heading>

        <P classes="font-w-reg">Review or edit your private invitation to friends & family below...</P>
        <div className="message mg-top--md d-flex j-between">
          Message:
          <Button text={buttonPillText} type="button" extraClass={buttonPillClasses} onClick={handleEditClick} />
        </div>
        <TextArea
          placeholder=''
          data-testid="messageTextArea"
          binding={initialText}
          onChange={(e) => setTextAreaValue(e.target.value)} // Update local state
          disabled={isTextAreaDisabled}
        />

        <p className="link__wrap mg-top--sm">
          <Icon icon="paperclip" size="small" inline />
          <Link to={memberLandingPage} target='_blank'>{memberLandingPage}</Link>
        </p>
        <DetectAndRedirectUser disabled={isAnchorButtonDisabled} groupId={groupId} btnText="Add Contacts" />
      </div>
    </BaseLayout>
  );
};

export default mustBeSignedIn(groupIdInUrl(InviteByEmail));
