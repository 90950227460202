import { useContext } from 'react'
import { FlagsmithContext } from 'contexts/flagsmith'
import { toSnakeCase } from 'helpers'

// This is kinda silly with all booleans, oh well
type ToggleFeatures = {
  joinGroupRedesign: boolean
  shareSheetPreview: boolean
  showChangeSubscriptionLinks: boolean
  showCreateGroupLinkOnHomepage: boolean
  showCreateGroupLinks: boolean
  showDebugFooter: boolean
  showPayPad: boolean
  smsIsDown: boolean
  newShareFlow: boolean
}

const toggleDefaults: ToggleFeatures = {
  joinGroupRedesign: false,
  shareSheetPreview: false,
  showChangeSubscriptionLinks: true,
  showCreateGroupLinkOnHomepage: true,
  showCreateGroupLinks: true,
  showDebugFooter: false,
  showPayPad: false,
  smsIsDown: false,
  newShareFlow: false,
}

/**
 * Returns a boolean for a simple feature toggle, preferring
 * a remotely-configured value.
 *
 * Cross-platform naming rules dicated that Flagsmith features are named
 * `snake_case`; the local `camelCase` must equate.
 *
 * BE AWARE: Flagsmith has an SDK setting to "Hide disabled flags from SDKs".
 * DO NOT ENABLE THIS. Doing so will make it impossible to distinguish between
 * "feature is turned off at Flagsmith" and "unknown feature", which would
 * cause all disabled features to fall back to the local defaults (defined
 * above).
 *
 * Implications:
 * - It's possible to define a local-only feature, intentionally or otherwise,
 *   by having the Flagsmith feature either not exist or not align with the
 *   name.
 * - Defining a new feature at Flagsmith has no effect whatsoever until added
 *   into the local type declaration (or that in useFeature).
 * - Again, enabling the "hide disabled flags" setting (DO NOT) makes it
 *   impossible remotely disable a feature, and instead means it would only be
 *   possible to remotely _enable_ a feature. Be defensive.
 *
 * If Flagsmith is unavailable, you'll also get the defaults above
 */

type ToggleFeatureNames = keyof ToggleFeatures
const useFeatureToggle = (feature: ToggleFeatureNames): boolean => {
  const flagsmithName = toSnakeCase(feature)

  const loadedValues = useContext(FlagsmithContext).loadedValues
  const remoteValue = loadedValues[flagsmithName]
  const isKnownFlag = remoteValue !== undefined

  if (isKnownFlag) {
    return remoteValue.enabled
  } else {
    return toggleDefaults[feature]
  }
}

export default useFeatureToggle
