import React from 'react'
import { Navigate, RouteProps } from 'react-router-dom'

import * as P from 'pages'
import { builder } from 'routes'

const routes: RouteProps[] = [
  { path: builder.group.all, element: <P.MyGroups /> },
  { path: builder.group.allowMembers(builder.placeholders.groupId), element: <P.AllowMembersToGroup /> },
  { path: builder.group.changeSubscription(builder.placeholders.groupId), element: <P.ChangeSubscription /> },
  { path: builder.group.createForSelf, element: <P.CreateGroupForSelf /> },
  { path: builder.group.createForOther, element: <P.CreateGroupForOther /> },
  { path: builder.group.depositHistory(builder.placeholders.groupId), element: <P.DepositHistory /> },
  { path: builder.group.inviteContacts(builder.placeholders.groupId), element: <P.InviteContacts /> },
  { path: builder.group.inviteByEmail(builder.placeholders.groupId), element: <P.InviteByEmail /> },
  { path: builder.group.inviteByEmailSuccess(builder.placeholders.groupId), element: <P.InviteByEmailSuccess /> },
  { path: builder.group.addContactsNonGmail(builder.placeholders.groupId), element: <P.AddContactsNonGmail /> },
  { path: builder.group.loadContactsGmail(builder.placeholders.groupId), element: <P.LoadContactsGmail /> },
  { path: builder.group.inviteRecipient(builder.placeholders.groupId), element: <P.InviteRecipient /> },
  { path: builder.group.kyc(builder.placeholders.groupId), element: <P.KYC /> },
  { path: builder.group.memberLanding(builder.placeholders.groupId), element: <P.MemberLanding /> },
  { path: builder.group.paymentHistory(builder.placeholders.groupId), element: <P.GroupPaymentHistory /> },
  { path: builder.group.recipientLanding(builder.placeholders.groupId), element: <P.RecipientLanding /> },
  { path: builder.group.setPublicMessage(builder.placeholders.groupId), element: <P.SetPublicMessage /> },
  { path: builder.group.setWelcomeNote(builder.placeholders.groupId), element: <P.SetWelcomeNote /> },
  { path: builder.group.settings(builder.placeholders.groupId), element: <P.GroupSettings /> },
  { path: builder.group.view(builder.placeholders.groupId), element: <P.GroupHome /> },
  { path: builder.group.waitingForApproval(builder.placeholders.groupId), element: <P.WaitingForApproval /> },
  { path: builder.site.contact, element: <P.Contact /> },
  { path: builder.site.home, element: <P.Home /> },
  { path: builder.site.faq, element: <P.FAQ /> },
  { path: builder.site.signIn, element: <P.SignIn /> },
  { path: builder.site.requestPasswordReset, element: <P.RequestPasswordReset /> },
  { path: builder.site.resetPassword, element: <P.ResetPassword /> },
  { path: builder.user.paymentHistory, element: <P.UserPaymentHistory /> },
  { path: builder.user.changePassword, element: <P.ChangePassword /> },
  { path: builder.user.settings, element: <P.UserSettings /> },
  { path: builder.user.confirmEmail(builder.placeholders.confirmCode), element: <P.ConfirmEmail /> },
  { path: builder.group.setGroupDetails, element: <P.SetPublicMessage /> },
  { path: builder.group.setGroupGoal, element: <P.CreateGroupSetGoal /> },
  { path: builder.group.setSelfGroup, element: <P.SetSelfGroup /> },
  { path: builder.group.editGroupDetails(builder.placeholders.groupId), element: <P.EditGroupDetails /> },

  // Redirects (to handle older links)
  { path: '/login', element: <Navigate to={builder.site.signIn} replace /> },
  // Internal tools
  { path: '/styleGuide', element: <P.StyleGuide /> },
]

export default routes
